import $ from 'jquery';
import setUA from './util/setUaBodyClass';
import AOS from 'aos';
import anime from 'animejs';
import inView from 'in-view';
import Map from './_map';

// Application entry point
$(() => {
  setUA();
  Map();
  AOS.init({
    offset: 200,
    duration: 500,
    easing: 'ease-in-out-quad'
  });

  // Wrap every letter in a span
  $('.ml10 .letters').each(function(){
    $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
  });

  inView('.ml10')
    .on('enter', el =>{
      if (!el.classList.contains('is-moved')){
        el.classList.add('is-moved');
        anime.timeline({loop: false})
          .add({
            targets: el.getElementsByClassName('letter'),
            rotateY: [-90, 0],
            duration: 1300,
            delay: function(el, i) {
              return 200 + 45 * i;
            }
          });
      }
    });

  // VARIABLES
  let $container = $(".instagram_container");
  let html = "";
  let windowWidth = $(window).innerWidth(),
    windowHeight = $(window).innerHeight(),
    desktopInstagramSize = windowWidth / 5,
    mobileInstagramSize = windowWidth / 3;
  $(window).on('resize', () => {
    windowWidth = $(window).innerWidth();
    windowHeight = $(window).innerHeight();
    desktopInstagramSize = windowWidth / 5;
    mobileInstagramSize = windowWidth / 3;
  })

  // HERO SECTION
  let $hero = $("#hero");
  $hero.height(windowHeight);
  $(window).on('resize', ()=>{
    $hero.height(windowHeight);
  })

  // A LINK ANCHOR
  $("a[href^='#']:not('.popup')").on('click', function () {
    let speed = 500,
      href = $(this).attr("href"),
      target = $(href == "#" || href == "" ? 'html' : href),
      position = target.offset().top;
    $("html, body").animate({scrollTop: position}, speed, "swing");
    return false;
  });

  // MENU BUTTON TOGGLE
  $(".btn_open").on('click', () => {
    if (!$(".header").hasClass('is-open')){
      $(".header").addClass('is-open');
      setTimeout(function(){
        $(".header").addClass('is-bg-show');
      }, 600)
      $(".nav > ul > li").each(function(index, item){
        setTimeout(function(){
          $(item).addClass('is-show');
        }, 200 + 100*index);
      })
    } else {
      $(".nav > ul > li").each(function(index, item){
        setTimeout(function(){
          $(item).removeClass('is-show');
        }, 200 + 100*index);
      })
      setTimeout(function(){
        $(".header").removeClass('is-bg-show');
        setTimeout(function(){
          $(".header").removeClass('is-open');
        }, 300)
      }, 700)
    }
  })

  // MENU CLOSE
  $(".nav > ul > li > a").on('click', () => {
    $(".nav > ul > li").each(function(index, item){
      setTimeout(function(){
        $(item).removeClass('is-show');
      }, 200 + 100*index);
    })
    setTimeout(function(){
      $(".header").removeClass('is-bg-show');
      setTimeout(function(){
        $(".header").removeClass('is-open');
      }, 300)
    }, 700)
  })


});
