/**
 * Created by Akihiro-Kato on 2016/06/19.
 */
'use strict';

import $ from 'jquery';

module.exports = function(){
  google.maps.event.addDomListener(window, 'load', init);

  function init() {
    var mapOptions1 = {
      zoom: ($(window).innerWidth() > 768) ? 18 : 16,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      draggable: false,
      center: new google.maps.LatLng(35.175161, 136.905376),
      styles: [
        {
          "featureType": "administrative",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#444444"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            },
            {
              "hue": "#ff0000"
            },
            {
              "saturation": "94"
            },
            {
              "lightness": "88"
            },
            {
              "weight": "3.01"
            },
            {
              "invert_lightness": true
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
            {
              "color": "#f2f2f2"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "saturation": -100
            },
            {
              "lightness": 45
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "visibility": "on"
            }
          ]
        }
      ]
    };
    var image = 'images/pin.png';
    var mapElement1 = document.getElementById('map1');
    var map1 = new google.maps.Map(mapElement1, mapOptions1);
    var marker1 = new google.maps.Marker({
      position: new google.maps.LatLng(35.175161, 136.905376),
      map: map1,
      icon: image
    });

    var mapOptions2 = {
      zoom: ($(window).innerWidth() > 768) ? 18 : 16,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      draggable: false,
      center: new google.maps.LatLng(35.662425, 139.716628),
      styles: [
        {
          "featureType": "administrative",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#444444"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            },
            {
              "hue": "#ff0000"
            },
            {
              "saturation": "94"
            },
            {
              "lightness": "88"
            },
            {
              "weight": "3.01"
            },
            {
              "invert_lightness": true
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
            {
              "color": "#f2f2f2"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "saturation": -100
            },
            {
              "lightness": 45
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "visibility": "on"
            }
          ]
        }
      ]
    };
    var mapElement2 = document.getElementById('map2');
    var map2 = new google.maps.Map(mapElement2, mapOptions2);
    var marker2 = new google.maps.Marker({
      position: new google.maps.LatLng(35.662425, 139.716628),
      map: map2,
      icon: image
    });
  }
};
